import React from "react";
import "../../Assets/Styles/Footer.css";
import {
  IoMdMap,
  IoIosCall,
  IoMdMail,
  IoIosArrowRoundForward,
} from "react-icons/io";

function Footer() {
  return (
    <div className="Footer">
      <div className="Footer-content">
        <div className="Footer-CompanyInfo">
          <div>
            <img
              src={require("../../Assets/Imgs/icon.png")}
              alt="Varaneek_logo"
              style={{ height: 100, width: 100 }}
            />
          </div>
          <div className="Footer-CompanyName">
            <div>Varaneek</div>
            <div>Technologies</div>
            <div>Private Limited</div>
          </div>
        </div>
        <div className="Footer-Info">
          <div className="Footer-leftBlock">
            <div className="Footer-leftBlock2">
              <div className="Footer-headers">Why Varaneek Technologies?</div>
              <div className="Footer-centerBlock3">
                <div className="Footer-icon">
                  <IoIosArrowRoundForward />
                </div>
                <div>
                  As a committed team we shall strive for being a trustworthy,
                  customer focused organization.
                </div>
              </div>
              <div className="Footer-centerBlock3">
                <div className="Footer-icon">
                  <IoIosArrowRoundForward />
                </div>
                <div>
                  We shall strive to achieve Quality Services by comprehending
                  their need through close interaction and by creating a global
                  network.
                </div>
              </div>
            </div>
          </div>
          <div className="Footer-leftBlock">
            <div className="Footer-leftBlock2">
              <div className="Footer-headers">Contact Information</div>
              <div className="Footer-centerBlock3">
                <div className="Footer-icon">
                  <IoMdMap />
                </div>
                <div>
                  <div className="Footer-AddrHighlist">Hyderabad</div>
                  <div className="Footer-Addrtext">
                    213, Amrutha ville, Rajbahavan Road, Somajiguda,
                    Hyderabad-500082.
                  </div>
                </div>
              </div>
              <div className="Footer-centerBlock3">
                <div className="Footer-icon">
                  <IoMdMap />
                </div>
                <div>
                  <div className="Footer-AddrHighlist">Bangalore</div>
                  <div className="Footer-Addrtext">
                    No. 81/2 Ground Floor, 2nd main, 1st cross, amco layout,
                    kodigehalli, sahakarnagar post, Bangalore -560092
                  </div>
                </div>
              </div>
              <div className="Footer-centerBlock3">
                <div className="Footer-icon">
                  <IoMdMap />
                </div>
                <div>
                  <div className="Footer-AddrHighlist">Chennai</div>
                  <div className="Footer-Addrtext">
                    No, 6/408, Muthtamil Nagar, East Avenue road Landmark next
                    Professional courier office Chennai-600118
                  </div>
                </div>
              </div>

              <div className="Footer-centerBlock3">
                <div className="Footer-icon">
                  <IoMdMail />
                </div>
                <div>info@varaneektech.com</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
