import React, { useState, useEffect } from "react";
import "../../Assets/Styles/Header.css";
import { Link } from "react-router-dom";
import { IoIosCall, IoMdMail, IoMdMenu } from "react-icons/io";
import { useSpring, animated } from "react-spring";
import { useHistory } from "react-router-dom";
function Header(propsOfComponent) {
  let history = useHistory();
  const [animate, setAnimate] = useState(false);
  const toggle = () => {
    setAnimate(!animate);
  };
  useEffect(() => {
    history.listen(() => {
      setAnimate(false);
      window.scrollTo(0, 0);
    });
  }, []);
  const props = useSpring({ marginTop: animate ? 0 : -400 });
  return (
    <>
      <div className="Header">
        <div className="ContactInfo">
          <span className="clickable">
            <IoMdMail />
            <span className="Mailtxt">
              <a
                href="mailto:info@varaneektech.com"
                className="LinksForContact"
                target="_top"
              >
                info@varaneektech.com
              </a>
            </span>
          </span>
        </div>
        <div className="HeaderCont">
          <div className="headCon">
            <div className="imgCon">
              <img
                src={require("../../Assets/Imgs/logo.jpg")}
                alt="Varaneek_logo"
                style={{ height: 50, width: 50 }}
              />
              <div className="titleCOnt">Varaneek Technologies</div>
            </div>
            <div className="menuCon">
              <div className="linkCon">
                <Link className="Links" to="/">
                  Home
                </Link>
              </div>
              <div className="linkCon">
                <Link className="Links" to="/Services">
                  Services
                </Link>
              </div>
              <div className="linkCon">
                <Link className="Links" to="/Vendors">
                  Vendors
                </Link>
              </div>
              <div className="linkCon">
                <Link className="Links" to="/Franchise">
                  Franchise
                </Link>
              </div>
              {/* <div className="linkCon"><Link className="Links" to="/Registration">Registration</Link></div> */}
              <div className="linkCon">
                <Link className="Links" to="/Contact">
                  Contact
                </Link>
              </div>
            </div>
            <div className="menuConButton">
              <span className="HamIcon" onClick={toggle}>
                <IoMdMenu />
              </span>
            </div>
          </div>
        </div>
      </div>
      <animated.div className="m-menuCon" style={props}>
        <Link className="Links2" to="/">
          <div className="linkCon-m">Home</div>
        </Link>
        <Link className="Links2" to="/Services">
          <div className="linkCon-m">Services</div>
        </Link>
        <Link className="Links2" to="/Vendors">
          <div className="linkCon-m">Vendors</div>
        </Link>
        <Link className="Links2" to="/Franchise">
          <div className="linkCon-m">Franchise</div>
        </Link>
        {/* <Link className="Links2" to="/Registration"><div className="linkCon-m">Registration</div></Link> */}
        <Link className="Links2" to="/Contact">
          <div className="linkCon-m">Contact</div>
        </Link>
      </animated.div>
    </>
  );
}

export default Header;
